import "./extension/extension";
import { IAppService, IAuthExceptionServiceEndPoints, IFxpAppContext, IPartnerBundle, IRouteInfo, IServiceEndPoints, ISharedComponentConfig, ISharedComponents, IStateConfig } from "@fxp/fxpservices";
import { StateParams } from "@uirouter/angular";
import { appSettings } from "./environments/common/appsettingsGenerator";
import { Feature, FxpPjmTelemetryName } from "./app/common/application.constants";
import { RouteName } from "./app/common/application.constants";
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class DMApp_routes implements IAppService {

    getAuthExceptionEndpoints(): Array<IAuthExceptionServiceEndPoints> {
        return [];
    }

    /* Including fxpAppContext here for reference since it was originally included with FXP bootstrapping. */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getRoutes(fxpAppContext: IFxpAppContext): IRouteInfo {
        // TODO: Filter the state collection based on approle using userContext.defaultAppRole and return the state collection.

        /**
         * All route information for pages related to customer engagement details and engagement context.
         * Includes Engagement summary pages, financials, manage wbs, etc...
         * Does not include Internal Engagement information
         */
        const engagementDetailStates: IStateConfig[] = [
            {
                name: RouteName.EngagementDetails,
                url: "/engagement/:engagementId",
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Engagement",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementDetails",
                    featureName: "EngagementDetails",
                    actionName: "ViewEngagementDetails"
                },
                redirectTo: RouteName.EngagementSummary,
            },
            {
                name: RouteName.EngagementFinancials,
                url: "/financials",
                sticky: true,
                params: new StateParams({
                    eventName: { value: "" }
                }),
                views: {
                    financials: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Engagement Financials",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementFinancials",
                    featureName: "EngagementFinancials",
                    actionName: "ViewEngagementFinancials"
                },
            },
            {
                name: RouteName.EngagementStaffing,
                url: "/staffing",
                params: new StateParams({
                    eventName: { value: "" },
                    bulkMaintenanceReferenceId: undefined,
                    entityId: undefined,
                    entityDescription: undefined
                }),
                sticky: true,
                views: {
                    staffing: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Staffing",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementStaffing",
                    featureName: "EngagementStaffing",
                    actionName: "ViewEngagementStaffing"
                },
            },
            {
                name: RouteName.EngagementManageSuppliers,
                url: "/managesuppliers",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    manageSuppliers: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Manage Suppliers",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementManageSuppliers",
                    featureName: "EngagementManageSuppliers",
                    actionName: "ViewEngagementManageSuppliers"
                },
            },
            {
                name: RouteName.EngagementMilestones,
                url: "/milestones",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    milestones: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Milestones",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagemenMilestones",
                    featureName: "EngagementMilestones",
                    actionName: "ViewEngagementMilestones"
                },
            },
            {
                name: RouteName.EngagementInvoices,
                url: "/invoices",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    invoices: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Customer Invoices",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementInvoices",
                    featureName: "EngagementInvoices",
                    actionName: "ViewEngagementInvoices"
                },
            },
            {
                name: RouteName.EngagementContactDetails,
                url: "/summary/contacts",
                sticky: true,
                views: {
                    contactDetails: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Engagement Contacts",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementContactDetails",
                    featureName: "EngagementContactDetails",
                    actionName: "ViewEngagementContactDetails"
                },
            },
            {
                name: RouteName.EngagementActuals,
                url: "/units",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    units: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Units",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementActuals",
                    featureName: "EngagementActuals",
                    actionName: "ViewEngagementActuals"
                },
            },
            {
                name: RouteName.EngagementNonProcuredMaterials,
                url: "/npm",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    nonProcuredMaterials: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Non-Procured Materials",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementNonProcuredMaterials",
                    featureName: "EngagementNonProcuredMaterials",
                    actionName: "ViewEngagementNonProcuredMaterials"
                },
            },
            {
                name: RouteName.EngagementPlanForecast,
                url: "/plan-forecast",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Plan & Forecast",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementPlanForecast",
                    featureName: "EngagementPlanForecast",
                    actionName: "ViewEngagementPlanForecast"
                },
            },
            {
                name: RouteName.EngagementAmendmentDetails,
                url: "/financials/amendment/:amendmentId/details",
                sticky: true,
                views: {
                    amendmentDetails: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Amendment Details",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementAmendmentDetails",
                    featureName: "EngagementAmendmentDetails",
                    actionName: "ViewEngagementAmendmentDetails"
                },
            },
            {
                name: RouteName.EngagementFinancialChangeRequest,
                url: "/financials/changeRequest/:crId/details",
                sticky: true,
                params: new StateParams({
                    changeRequest: {}
                }),
                views: {
                    changeRequest: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Engagement Summary",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ViewFinancialChangeRequest",
                    featureName: Feature.ViewChangeRequest,
                    actionName: Feature.ViewChangeRequest
                },
            },
            {
                name: RouteName.EngagementFinancialChangeRequestForm,
                url: "/financials/change-request/create",
                sticky: true,
                params: new StateParams({
                    hideNavigationBar: true,
                    projectsThatFailedThresholdCheck: [],
                    initializedFromPlanAndForecast: false,
                    initializedFromNewForecastExperience: false,
                    demandDiffData: []
                }),
                views: {
                    changeRequestForm: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Create Financial Change Request",
                    pageTitle: "Create Financial Change Request",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "CreateEngagementFinancialChangeRequestForm"
                },
            },
            {
                name: RouteName.EngagementManageEBS,
                url: "/ebs",
                sticky: true,
                params: new StateParams({
                    eventName: { value: "" },
                }),
                views: {
                    ebs: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Manage EBS",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementManageEBS",
                    featureName: "EngagementManageEBS",
                    actionName: "ViewEngagementManageEBS"
                },
            },
            {
                name: RouteName.EngagementSummaryV2,
                url: "/summaryv2",
                sticky: true,
                params: new StateParams({
                    eventName: { value: "" }
                }),
                views: {
                    summaryv2: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Engagement Summary V2",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementSummaryV2",
                    featureName: "EngagementSummaryV2",
                    actionName: "ViewEngagementSummaryV2"
                },
            },
            {
                name: RouteName.EngagementSummary,
                url: "/summary",
                sticky: true,
                params: new StateParams({
                    eventName: { value: "" }
                }),
                views: {
                    summary: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Engagement Summary V2",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementSummaryV2",
                    featureName: "EngagementSummaryV2",
                    actionName: "ViewEngagementSummaryV2"
                },
            },
            {
                name: RouteName.EngagementWbsDetails,
                url: "/wbsdetails",
                sticky: true,
                params: new StateParams({
                    hideNavigationBar: true,
                }),
                views: {
                    wbsdetails: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Wbs Details",
                    pageTitle: "Wbs Details",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "EngagementWbsDetails",
                    featureName: "EngagementWbsDetails",
                    actionName: "ViewEngagementWbsDetails"
                },
            },
            {
                name: RouteName.PlanAndForecastV2,
                url: "/plan-forecast-v2",
                sticky: true,
                params: new StateParams({
                    eventName: { value: "" }
                }),
                views: {
                    planAndForecastv2: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Plan Forecast",
                    pageTitle: "Plan Forecast",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName
                },
            },
            {
                name: RouteName.EngagementMisalignedAmendmentDetails,
                url: "/financials/amendment/:amendmentId/misalignment",
                sticky: true,
                views: {
                    misalignedAmendmentDetails: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Amendment Details",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName
                },
            },
        ];

        /**
         * All route information for pages related to project details and project context.
         * Includes Project summary, financials, manage wbs, etc...
         */
        const projectDetailStates: IStateConfig[] = [
            {
                name: RouteName.ProjectDetails,
                url: "/project/:projectId",
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Project",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectDetails",
                    featureName: "ProjectDetails",
                    actionName: "ViewProjectDetails"
                },
                redirectTo: RouteName.ProjectSummary,
            },
            {
                name: RouteName.ProjectFinancials,
                url: "/financials",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    financials: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Project Financials",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectFinancials",
                    featureName: "ProjectFinancials",
                    actionName: "ViewProjectFinancials"
                },
            },
            {
                name: RouteName.ProjectContactDetails,
                url: "/summary/contacts",
                sticky: true,
                views: {
                    contactDetails: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Project Contacts",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectContactDetails",
                    featureName: "ProjectContactDetails",
                    actionName: "ViewProjectContactDetails"
                },
            },
            {
                name: RouteName.ProjectPlanForecast,
                url: "/plan-forecast",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                data: {
                    headerName: "Plan & Forecast",
                    breadcrumbText: "Project",
                    pageTitle: "Plan & Forecast",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectPlanForecast",
                    featureName: "ProjectPlanForecast",
                    actionName: "ViewProjectPlanForecast"
                },
            },
            {
                name: RouteName.ProjectStaffing,
                url: "/staffing",
                params: new StateParams({
                    eventName: { value: "" },
                    bulkMaintenanceReferenceId: undefined,
                    entityId: undefined,
                    entityDescription: undefined
                }),
                sticky: true,
                views: {
                    staffing: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Staffing",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectStaffing",
                    featureName: "ProjectStaffing",
                    actionName: "ViewProjectStaffing"
                },
            },
            {
                name: RouteName.ProjectManageSuppliers,
                url: "/managesuppliers",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    manageSuppliers: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Manage Suppliers",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectManageSuppliers",
                    featureName: "ProjectManageSuppliers",
                    actionName: "ViewProjectManageSuppliers"
                },
            },
            {
                name: RouteName.ProjectMilestones,
                url: "/milestones",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    milestones: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Milestones",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectMilestones",
                    featureName: "ProjectMilestones",
                    actionName: "ViewProjectMilestones"
                },
            },
            {
                name: RouteName.ProjectInvoices,
                url: "/invoices",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    invoices: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Customer Invoices",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectInvoices",
                    featureName: "ProjectInvoices",
                    actionName: "ViewProjectInvoices"
                },
            },
            {
                name: RouteName.ProjectActuals,
                url: "/units",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    units: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Units",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectActuals",
                    featureName: "ProjectActuals",
                    actionName: "ViewProjectActuals"
                },
            },
            {
                name: RouteName.ProjectNonProcuredMaterials,
                url: "/npm",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    nonProcuredMaterials: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Non-Procured Materials",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectNonProcuredMaterials",
                    featureName: "ProjectNonProcuredMaterials",
                    actionName: "ViewProjectNonProcuredMaterials"
                },
            },
            {
                name: RouteName.ProjectAmendmentDetails,
                url: "/financials/amendment/:amendmentId/details",
                sticky: true,
                views: {
                    amendmentDetails: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Amendment Details",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectAmendmentDetails",
                    featureName: "ProjectAmendmentDetails",
                    actionName: "ViewProjectAmendmentDetails"
                },
            },
            {
                name: RouteName.ProjectFinancialChangeRequest,
                url: "/financials/changeRequest/:crId/details",
                sticky: true,
                params: new StateParams({
                    changeRequest: {}
                }),
                views: {
                    changeRequest: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Engagement Summary",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ViewFinancialChangeRequest",
                    featureName: Feature.ViewChangeRequest,
                    actionName: Feature.ViewChangeRequest
                },
            },
            {
                name: RouteName.ProjectFinancialChangeRequestForm,
                url: "/financials/change-request/create",
                sticky: true,
                params: new StateParams({
                    hideNavigationBar: true,
                    projectsThatFailedThresholdCheck: [],
                    initializedFromPlanAndForecast: false,
                    initializedFromNewForecastExperience: false,
                    demandDiffData: []
                }),
                views: {
                    changeRequestForm: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Create Financial Change Request",
                    pageTitle: "Create Financial Change Request",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "CreateProjectFinancialChangeRequestForm"
                },
            },
            {
                name: RouteName.ProjectManageEBS,
                url: "/ebs",
                sticky: true,
                params: new StateParams({
                    eventName: { value: "" }
                }),
                views: {
                    ebs: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Manage EBS",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectManageEBS",
                    featureName: "ProjectManageEBS",
                    actionName: "ViewProjectManageEBS"
                },
            },
            {
                name: RouteName.ProjectSummaryV2,
                url: "/summaryv2",
                sticky: true,
                params: new StateParams({
                    eventName: { value: "" }
                }),
                views: {
                    summaryv2: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Project Summary V2",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectSummaryV2",
                    featureName: "ProjectSummaryV2",
                    actionName: "ViewProjectSummaryV2"
                },
            },
            {
                name: RouteName.ProjectSummary,
                url: "/summary",
                sticky: true,
                params: new StateParams({
                    eventName: { value: "" }
                }),
                views: {
                    summary: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Project Summary V2",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectSummaryV2",
                    featureName: "ProjectSummaryV2",
                    actionName: "ViewProjectSummaryV2"
                },
            },
            {
                name: RouteName.ProjectWbsDetails,
                url: "/wbsdetails",
                sticky: true,
                params: new StateParams({
                    hideNavigationBar: true,
                }),
                views: {
                    wbsdetails: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Wbs Details",
                    pageTitle: "Wbs Details",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ProjectWbsDetails",
                    featureName: "ProjectWbsDetails",
                    actionName: "ViewProjectWbsDetails"
                },
            },
            {
                name: RouteName.ProjectMisalignedAmendmentDetails,
                url: "/financials/amendment/:amendmentId/misalignment",
                sticky: true,
                views: {
                    misalignedAmendmentDetails: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project",
                    pageTitle: "Amendment Details",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName
                },
            },
        ];

        /**
         * All route information for pages related to Internal Engagements.
         * Includes Internal Engagement summary, Internal Engagement Financials, etc...
         * Also includes Create Internal Engagement page.
         */
        const internalEngagementStates: IStateConfig[] = [
            {
                name: RouteName.CreateInternalEngagementV2,
                url: "/createInternalEngagementV2",
                params: new StateParams({ hideNavigationBar: true }),
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Create Internal Engagement",
                    pageTitle: "Create Internal Engagement",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "CreateInternalEngagement"
                },
            },
            {
                name: RouteName.BulkCreateInternalEngagement,
                url: "/bulkCreateInternalEngagement",
                params: new StateParams({ uploadedBulkIntEngData: {} }),
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Bulk Create Internal Engagement",
                    pageTitle: "Bulk Create Internal Engagement",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "BulkCreateInternalEngagement"
                },
            },
            // {
            //   name: RouteName.CreateInternalEngagement,
            //   url: "/createInternalEngagement",
            //   component: NewInternalEngagementComponent,
            //   params: new StateParams({ hideNavigationBar: true }),
            //   data: {
            //     headerName: "Project Management",
            //     breadcrumbText: "Create Internal Engagement",
            //     pageTitle: "Create Internal Engagement",
            //     style: "icon icon-people"
            //   },
            //   ngModule: NewInternalEngagementModule
            // },
            {
                name: RouteName.InternalEngagementDetails,
                url: "/internalEngagement/:engagementId",
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Internal Engagement",
                    pageTitle: "Internal Engagement",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ViewInternalEngagementDetails"
                },
                redirectTo: RouteName.InternalEngagementSummary
            },
            {
                name: RouteName.InternalEngagementSummary,
                url: "/summary",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    summary: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Internal Engagement Summary",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ViewInternalEngagementSummary",
                    featureName: Feature.ViewInternalEngagementSummary,
                    actionName: Feature.ViewInternalEngagementSummary
                },
            },
            {
                name: RouteName.InternalEngagementFinancials,
                url: "/financials",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    financials: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Internal Engagement Financials",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ViewInternalEngagementFinancials",
                    featureName: Feature.ViewInternalEngagementFinancials,
                    actionName: Feature.ViewInternalEngagementFinancials
                },
            },
            {
                name: RouteName.InternalEngagementStaffing,
                url: "/staffing",
                params: new StateParams({
                    eventName: { value: "" }
                }),
                sticky: true,
                views: {
                    staffing: {
                    }
                },
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Engagement",
                    pageTitle: "Internal Engagement Staffing",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "ViewInternalEngagementStaffing",
                    featureName: Feature.ViewInternalEngagementStaffing,
                    actionName: Feature.ViewInternalEngagementStaffing
                },
            }
        ];

        /**
         * All route information for misc pages, unrelated to single projects or engagements.
         * Includes landing page, reports, Portfolio, Search, etc...
         */
        const miscStates: IStateConfig[] = [
            {
                name: "helloworld",
                url: "/helloworld",
                data: {
                    headerName: "Hello World",
                    breadcrumbText: "Hello World Component",
                    pageTitle: "Hello World Title",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName
                },
            },
            {
                name: "InlineSurveyComponent",
                url: "/inlineSurvey",
                data: {
                    headerName: "Hello World",
                    breadcrumbText: "Hello World Component",
                    pageTitle: "Hello World Title",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName
                },
            },
            {
                name: RouteName.Reports,
                url: "/reports",
                params: new StateParams({ hideNavigationBar: true }),
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Reports",
                    pageTitle: "Project Management Reports",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "Reports",
                    featureName: "Reports",
                    actionName: "ViewReports"
                },
            },
            {
                name: RouteName.Entry,
                url: "/pjm",
                params: new StateParams({ loadFromCache: true }),
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Project Management",
                    pageTitle: "",
                    style: "",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "Entry",
                    featureName: "Entry",
                    actionName: "Entry"
                },
                /* This state is the parent state of our entire app, and hosts global information such as the navigation,
                but this is not a final state for users to see, so we always redirect to entry.portfolio child state. */
                redirectTo: RouteName.Portfolio,
            },
            {
                name: RouteName.Search,
                url: "/search",
                params: new StateParams({ searchText: undefined, searchAttribute: undefined, isAdvancedSearch: false }),
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "Search",
                    pageTitle: "Search in My Domain",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "Search",
                    featureName: "Search",
                    actionName: "ViewSearch"
                },
            },
            {
                name: RouteName.Portfolio,
                url: "/portfolio?:showBulkIntEngSummary&:bulkReferenceId&:isnavigationfromsubcon",
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "My Portfolio",
                    pageTitle: "My Portfolio",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "Portfolio",
                    featureName: "ShowBulkInternalEngagementSummary",
                    actionName: "ShowBulkInternalEngagementSummary"
                },
            },
            {
                name: RouteName.FinancialPlanApprovalsByCrId,
                url: "/financial-plan-approvals/:crid",
                params: new StateParams({ hideNavigationBar: true }),
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "My Approvals",
                    pageTitle: "My Financial Plan Approvals",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "FinancialPlanApprovalsByCrId",
                    featureName: "FinancialPlanApprovalsByCrId",
                    actionName: "ViewFinancialPlanApprovalsByCrId"
                },
            },
            {
                name: RouteName.FinancialPlanApprovals,
                url: "/financial-plan-approvals",
                params: new StateParams({ hideNavigationBar: true }),
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "My Approvals",
                    pageTitle: "My Financial Plan Approvals",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "FinancialPlanApprovals",
                    featureName: "FinancialPlanApprovals",
                    actionName: "ViewFinancialPlanApprovals"
                },
            },
            {
                name: RouteName.Unauthorized,
                url: "/unauthorized",
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "",
                    pageTitle: "Unauthorized",
                    style: "icon icon-people",
                    partnerTelemetryName: FxpPjmTelemetryName
                },
            },
            {
                name: RouteName.Portfolio,
                url: "/portfolio",
                data: {
                    headerName: "Project Management",
                    breadcrumbText: "My Portfolio",
                    pageTitle: "My Portfolio",
                    style: "icon icon-people",
                    appNameForTelemetry: FxpPjmTelemetryName,
                    partnerTelemetryName: FxpPjmTelemetryName,
                    pageNameForTelemetry: "Portfolio",
                    featureName: "Portfolio",
                    actionName: "ViewPortfolio"
                },
            },
        ];


        /* All routes combined into a single array */
        const stateCollection: IStateConfig[] = engagementDetailStates.concat(projectDetailStates, internalEngagementStates, miscStates);

        return {
            applicationName: "DMApp",
            sharedBundles: ["DMApp-Bundle"],
            routes: stateCollection
        };
    }

    /**
     * Get Service End Points, a function required by FXP in order to compile and build into their platform.
     */
    public getServiceEndPoints(): IServiceEndPoints[] {
        return appSettings().serviceEndPoints;
    }

    public getBundles(): IPartnerBundle[] {
        const baseUrl = appSettings().cdnBaseUrl;
        const bundlesData = [
            {
                name: "DMApp-Bundle",
                files: [
                    `${baseUrl}/vendor.bundle.js`,
                    `${baseUrl}/main.bundle.js`,
                    `${baseUrl}/styles.bundle.js`
                ],
                sequentialLoading: true
            }
        ];
        return bundlesData;
    }

    public getSharedComponentsInfo(): ISharedComponents {
        const inlineSurveySharedComponent: ISharedComponentConfig = {
            componentDescription: "This component renders inline survey feedback.",
            componentInputs: [],
            componentOutputs: [],
            componentName: "InlineSurveyComponent"
        };

        const syncEACToPlanSubmitComponent: ISharedComponentConfig = {
            componentDescription: "This component shows sync EAC to plan button.",
            componentInputs: [
                {
                    variableName: "syncEACPlanData",
                    variableDescription: "Modal representing information needed to start sync EAC to plan."
                },
                {
                    variableName: "selectedEntityId",
                    variableDescription: "Represents entity for which sync is being done - Project/Task/Demand"
                }
            ],
            componentOutputs: [],
            componentName: "dmapp.synceactoplansubmitcomponent"
        };

        const notificationBarComponent: ISharedComponentConfig = {
            componentDescription: "",
            componentInputs: [],
            componentOutputs: [],
            componentName: "dmapp.notificationbarcomponent"
        };

        const syncEACToPlanNotificationWrapperComponent: ISharedComponentConfig = {
            componentDescription: "",
            componentInputs: [],
            componentOutputs: [],
            componentName: "dmapp.synceactoplannotificationwrappercomponent"
        };

        return {
            appName: "DMApp",
            components: [inlineSurveySharedComponent, syncEACToPlanSubmitComponent, notificationBarComponent, syncEACToPlanNotificationWrapperComponent],
            disableSharing: false,
            sharedBundles: []
        };
    }
}

PartnerAppRegistrationService.registerLazyLoadedApp(DMApp_routes);
